import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { withRouter, Link } from "react-router-dom";
import { colors } from "../../css/colors";
import { connect } from "react-redux";
import { messages } from "../products/constant";
import { CRED } from "../../config/cred";
import CONFIG from "../../config";
import ErrorPage, { ErrorMessageHandling } from "../../components/error";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import PaymentGatewayModal from "../../components/paymentGatewayModal/PaymentGatewayModal";
import Alert from "../../components/alert";
import {
  getPaymentGateway,
  getAtomPaymentURL,
  getPaypalPaymentURL,
  getPaymentType,
  getPaymentGatewayChecking,
} from "./payment";
import axios from "axios";
import ExtraCharge from "./extraCharge";
import { withStatement } from "@babel/types";

class MyCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart_products: [],
      loader: true,
      success: false,
      currency_code: null,
      country_id: null,
      cart_contain_multiple_country_products: false,
      available_offers_block_open: false,
      available_offers: [],
      total_amount: null,
      discount: 0,
      coupon_code: "",
      coupon_applied: false,
      coupon_details: {},
      cart_contain_purchased_products: false,
      button_disabled: false,
      loader1: false,
      show_alert: false,
      heading: "",
      content: "",
      accept_tc: false,
      productInstituteId: "",
      add_link: false,
      apply_extra_charge: false,
      extra_charge: 0,
      amount_with_charge: 0,
      show_extra_charge_pop_up: false,
      show_payment_popup: false,
      select_payment_gateway: "razorpay",
      showPaymentbutton: false,
      public_token: "",
      is_oAuth_done: "",
      gatewayDta: {},
    };
  }

  handleClose = () => {};
  handleOk = () => {};

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getCartProducts();
    getPaymentGatewayChecking(
      this.props,
      this.paymentButtonShow,
      this.updateData
    );
  };

  paymentButtonShow = (flag) => {
    this.setState({ showPaymentbutton: flag });
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getCartProducts = async () => {
    let headers = {
      headers: {
        "x-prod-inst-id": this.props.institute_id,
        "x-prod-user-id": this.props.user_id == 0 ? "" : this.props.user_id,
        Authorization:
          this.props.user_id == 0
            ? this.props.access_token.auth_token
            : this.props.student_auth,
      },
    };
    let payload = { product_ids: this.props.cart };
    try {
      let data = await HELPERS.ApiCall(
        apis.CART_LIST,
        payload,
        "POST",
        "product",
        headers
      );
      if (data.status == 200 && data.data.status_code == 200) {
        let flag = false,
          currency_code = null,
          country_id = null,
          products = data.data.result;

        for (let i = 0; i < products.length; i++) {
          if (currency_code == null || country_id == null) {
            currency_code =
              products[i].country_details &&
              products[i].country_details.currency_code
                ? products[i].country_details.currency_code
                : "";
            country_id =
              products[i].country_details &&
              products[i].country_details.country_id
                ? products[i].country_details.country_id
                : null;
          } else if (
            currency_code !== null &&
            country_id !== null &&
            products[i].country_details &&
            products[i].country_details.currency_code &&
            products[i].country_details.currency_code !== currency_code
          ) {
            flag = true;
            break;
          }
        }

        let price = 0,
          available_offers = [],
          cart_contain_purchased_products = false;
        for (let index = 0; index < products.length; index++) {
          price = price + products[index].price;
          products[index].available_offers.forEach((el, i) => {
            el["country_details"] = products[index].country_details;
          });
          available_offers = [
            ...available_offers,
            ...products[index].available_offers,
          ];
          if (products[index].is_enrolled) {
            cart_contain_purchased_products = true;
          }
        }

        let ProductiData = [];
        data.data.result.forEach((item) => {
          ProductiData.push(item.entity_id);
        });

        this.props.dispatch({
          type: "ADD_TO_CART",
          payload: {
            id: ProductiData,
          },
        });

        await this.setState({
          loader: false,
          cart_products: data.data.result,
          success: true,
          cart_contain_multiple_country_products: flag,
          currency_code: currency_code,
          country_id: country_id,
          total_amount: price,
          available_offers: available_offers,
          cart_contain_purchased_products: cart_contain_purchased_products,
        });
      } else {
        this.message("e", data.data.message);
        this.setState({ loader: false, success: false });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false });
    }
  };

  remove = async (item) => {
    await this.props.dispatch({
      type: "REMOVE_FROM_CART",
      payload: { id: item.id },
    });
    this.getCartProducts();
    this.setState({
      loader: true,
      country_details: {},
      coupon_code: "",
      coupon_applied: false,
      discount: 0,
    });
  };

  applyCoupon = async () => {
    this.setState({ button_disabled: true, loader1: true });

    const { user_id, access_token, student_auth, institute_id } = this.props;

    let headers = {
      headers: {
        "x-prod-user-id": user_id.toString(),
        "x-prod-inst-id": institute_id,
        Authorization: user_id == 0 ? access_token.auth_token : student_auth,
      },
    };
    let payload = {
      coupon_code: this.state.coupon_code,
      product_id: this.props.cart,
    };
    try {
      let data = await HELPERS.ApiCall(
        apis.APPLY_COUPON,
        payload,
        "POST",
        "product",
        headers
      );
      this.setState({ button_disabled: false });
      if (data.status == 200 && data.data.status_code == 200) {
        this.setState({
          loader1: false,
          coupon_applied: true,
          coupon_code: "",
          coupon_details: data.data.result,
          discount: data.data.result.discount_amount,
        });
      } else if (data.status && data.data && data.data.status_code) {
        let message =
          data.data.message == "Error"
            ? messages.coupon_invalid
            : data.data.message;
        this.message("e", message);
        this.setState({ loader1: false });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader1: false, button_disabled: false });
    }
  };

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  createOrder = async () => {
    console.log("hi")
    this.setState({ loader1: true });
    const { coupon_applied, coupon_details, amount_with_charge } = this.state;
    const { user_id, access_token, student_auth, institute_id } = this.props;

    let headers = {
      headers: {
        "x-prod-user-id": user_id.toString(),
        "x-prod-inst-id": institute_id,
        Authorization: student_auth,
      },
    };

    let payload = {};
    let amount = amount_with_charge;
    if (!coupon_applied || (coupon_applied && amount !== 0)) {
      payload = { product_ids: this.props.cart, total_amount: amount };
    } else {
      payload = {
        product_ids: this.props.cart,
        total_amount: 0,
        offer_apply_dto: coupon_details,
      };
    }

    try {
      let data = await HELPERS.ApiCall(
        apis.CREATE_ORDER,
        payload,
        "POST",
        "product",
        headers
      );
      {console.log(data.data.result)}
      {console.log(data.data.result.total_amount)}
      {console.log(data.data.result.status)}
    
      if (data.status == 200 && data.data.status_code == 200) {
        if (data.data.result && data.data.result.product_owner_institute_id) {
          this.setState({
            productInstituteId: data.data.result.product_owner_institute_id,
          });
        }
        {console.log(data.data.result)}
        {console.log(data.data.result.total_amount)}
        {console.log(data.data.result.status)}
      
        if (
          data.data.result.status == 20 ||
          data.data.result.total_amount == 0
        ) {
          this.setState({ loader1: false });
          this.orderPlaced(data.data.result);
        } else if (data.data.result.total_amount !== 0) {
          let temp = {
            ...this.props,
            ...this.state,
            amount,
            setLoader: (val) => {
              this.setState({ loader1: val });
            },

            useRazorpay: () => {
              this.openCheckout(data.data.result);
            },
            message: this.message,
            useAtom: async (account, merchant_id, email_id) => {
              let payment_data = await this.getPaymentPayload(
                account,
                "Atom",
                data.data.result
              );
              let payment_url = await getAtomPaymentURL(payment_data, (val) => {
                this.setState({ loader1: false });
                this.setState({ loader: false });
              });

              const options = {
                atomTokenId: `${payment_url?.token_id}`,
                merchId: `${merchant_id}`,
                custEmail: `${
                  this.props?.user_info?.email_id
                    ? this.props?.user_info?.email_id
                    : email_id
                }`,
                custMobile: `${this.props?.user_info?.mobile_no}`,
                returnUrl: `${CONFIG.BASE_URL}/api/v1/payment-gateway/payments/process/${this.props.institute_id}`,
              };

              window.AtomPaynetz(options);

              // window.open(payment_url, "_self");
            },
            usePaypal: async (account) => {
              let payment_data = await this.getPaymentPayload(
                account,
                "PayPal",
                data.data.result
              );
              let temp1 = {
                ...this.props,
                payload: payment_data,
                setLoader: (val) => {
                  this.setState({ loader1: val });
                },
              };
              let payment_url = await getPaypalPaymentURL(temp1);
              window.open(payment_url.replace("redirect:", ""), "_self");
            },
          };
          getPaymentGateway(temp);
        } else {
          this.setState({ loader1: false });
        }
      } else {
        this.message("e", data.data.message);
        this.setState({ loader1: false });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader1: false });
    }
  };

  orderCreateForRazorPay = async (payload) => {
    this.setState({ isLoading: true });
    const { user_id, access_token, student_auth, institute_id } = this.props;
    try {
      let headers = {
        headers: {
          "x-prod-user-id": user_id.toString(),
          "x-prod-inst-id": institute_id,
          Authorization: user_id == 0 ? access_token.auth_token : student_auth,
        },
      };
      const response = await axios.post(
        `${CONFIG.BASE_URL}/api/v2/onlinePayment/create_order`,
        payload,
        headers
      );
      if (response.data.statusCode === 200) {
        const { order_id, razorpay_order_id } = response.data.result;
        // console.log('Order IDs:', order_id, razorpay_order_id)
        return { order_id, razorpay_order_id };
      } else {
        this.message(response.data.message);
      }
    } catch (err) {
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : messages.unableToPay;
      this.message(errorMessage);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  updateOrderForRazorPay = async (razorpay_payment_id, order_id) => {
    const payload = {
      razorpay_payment_id: razorpay_payment_id,
      order_id: order_id,
    };
    const headers = {
      Authorization: this.props.student_auth,
      "Content-Type": "application/json",
      source: "WEB",
    };
    try {
      const response = await axios.post(
        `${CONFIG.BASE_URL}/api/v2/onlinePayment/update-order-payment-detail`,
        payload,
        headers
      );
      if (response.data.statusCode === 200) {
        console.log(response.data);
        this.orderPlaced();
      }
    } catch (err) {
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : messages.unableToPay;
      this.message(errorMessage);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getPaymentPayload = (account, gateway, order_data) => {
    const { user_id, institute_id } = this.props;

    let payload = {
      entity_id: order_data.entity_id,
      payment_gateway_transaction_id: "",
      status: 20,
      payment_for: "Product",
      payment_gateway_account_type: account,
      payment_gateway_name: gateway,
      country_id: this.state.country_id,
      currency_code: this.state.currency_code,
      response_code: 200,
      paytab_token: "",
      amount: this.state.total_amount - this.state.discount,
      payment_source: "WEB",
      institute_id: institute_id,
      user_id: user_id,
    };

    if (Object.keys(this.state.coupon_details).length > 0) {
      payload["offer_apply_dto"] = this.state.coupon_details;
    }
    if (gateway == "PayPal") {
      payload["intent"] = "ORDER";
    }
    return payload;
  };

  opnAlrtPopForNotIntegratePayGat = async () => {
    this.handleOk = () => {
      this.setState({ show_alert: false });
    };
    await this.setState({
      show_alert: true,
      content:
        "We apologize, but our online payment processing is currently unavailable. For assistance, please contact your institute. Thank You!",
    });
  };
  openCheckout = async (order_data) => {
    const { user_id, institute_id, user_info, user_type } = this.props;
    const {
      coupon_applied,
      country_id,
      currency_code,
      amount_with_charge,
      coupon_details,
    } = this.state;

    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      this.handleClose = () => {
        this.setState({ show_alert: false });
      };
      this.handleOk = () => {
        this.setState({ show_alert: false });
      };
      await this.setState({
        show_alert: true,
        content:
          "Something went wrong. Kindly check your network connection and try again.",
      });
      return;
    } else {
      let notes = {
        entity_id: order_data.entity_id,
        // "payment_gateway_transaction_id": "",
        // "status": 20,
        // "payment_for": "Product",
        payment_gateway_account_type: "Proctur",
        payment_gateway_name: "Razorpay",
        country_id: country_id,
        currency_code: currency_code,
        response_code: 200,
        // "paytab_token": "",
        amount: amount_with_charge,
        payment_source: "WEB",
        institute_id: institute_id,
        user_id: user_id,
        name: user_info.name,
      };
      if (coupon_applied) {
        notes["offer_apply_dto"] = JSON.stringify(coupon_details);
      }

      const orderCreatetRequest = {
        amount: (amount_with_charge * 100).toFixed(2),
        currency: "INR",
        notes: {
          amount: amount_with_charge,
          country_id: country_id,
          currency_code: currency_code,
          entity_id: order_data.entity_id,
          name: user_info.name,
          institute_id: institute_id,
          payment_source: "WEB",
          user_id: user_id,
          student_id: user_type == 99 ? 0 : user_info.student_id,
          payment_for: "Product",
        },
      };
      // console.log("ssss",orderCreatetRequest,this.state.is_oAuth_done,"dddd",this.state.public_token)
      //orderCreateApi
      const result = this.state.is_oAuth_done
        ? await this.orderCreateForRazorPay(orderCreatetRequest)
        : null;
      //payload for RazorPay api
      let options = {
        key: this.state.is_oAuth_done
          ? this.state.public_token
          : CRED.RAZORPAY_KEY,
        amount: (Number(amount_with_charge) * 100).toFixed(2), // 2000 paise = INR 20, amount in paisa
        currency: "INR",
        name: "Merchant Name",
        description: "PP",
        // order_id: this.state.is_oAuth_done ? result?.razorpay_order_id : null,

        handler: (response) => {
          if (this.state?.is_oAuth_done) {
            this.setState({ isLoading: true });
            this.updateOrderForRazorPay(
              response.razorpay_payment_id,
              result?.order_id
            );
          } else {
            this.updateOrder(true, order_data, response.razorpay_payment_id);
          }
        },
        theme: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--theme-color"
          ),
        },
        notes: notes,
      };
      if (this.state.is_oAuth_done) {
        options["order_id"] = result?.razorpay_order_id;
      }
      if (this.state.is_oAuth_done) {
        if (!result?.razorpay_order_id) {
          this.message("e", "Payment Order is not created");
          return;
        }
      }
      console.log("ss", options);
      let rzp = new window.Razorpay(options);
      rzp.on("payment.failed", (response) => {
        // this.updateOrder(false, order_data, '');
      });
      rzp.open();
    }
  };

  updateOrder = async (flag, order_data, transaction_id) => {
    this.setState({ loader1: true });
    const {
      coupon_applied,
      country_id,
      currency_code,
      total_amount,
      discount,
      coupon_details,
    } = this.state;
    const { user_id, access_token, student_auth, institute_id } = this.props;

    let headers = {
      headers: {
        "x-prod-user-id": user_id.toString(),
        "x-prod-inst-id": institute_id,
        Authorization: student_auth,
        Source: "WEB",
      },
    };

    let amount = total_amount - discount;
    let payload = {
      entity_id: order_data.entity_id,
      payment_gateway_transaction_id: transaction_id,
      status: flag ? 20 : 30,
      payment_for: "Product",
      payment_gateway_account_type: "Proctur",
      payment_gateway_name: "Razorpay",
      country_id: country_id,
      currency_code: currency_code,
      response_code: flag ? 200 : 30,
      paytab_token: "",
    };
    if (coupon_applied) {
      payload["offer_apply_dto"] = coupon_details;
    }

    await axios
      .post(CONFIG.PRODUCT_BASE_URL + apis.UPDATE_ORDER, payload, headers)
      .then((data) => {
        if (data.data.result.status !== 20) {
          // this.handleClose = () => { this.setState({ show_alert: false }) }
          // this.handleOk = () => { this.setState({ show_alert: false }) }
          // this.setState({ show_alert: true, content: messages.unableToPay })
        } else {
          this.orderPlaced();
        }
        this.setState({ loader1: false });
      })
      .catch((error) => {
        if (error.response && error.response.status == 403) {
          this.props.dispatch({
            type: "LOGOUT",
            message:
              error.response.data && error.response.data.message
                ? error.response.data.message
                : "",
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          Array.isArray(error.response.data.error) &&
          error.response.data.error.length > 0
        ) {
          this.message("e", error.response.data.error[0].error_message);
        } else {
          this.message("e", ErrorMessageHandling(error));
        }
        this.setState({ loader1: false });
      });
  };

  orderPlaced = async () => {
    await this.props.dispatch({
      type: "EMPTY_CART",
    });
    this.getCartProducts();
    // alert('Product purchased successfully!');
    this.props.history.push({
      pathname: "/thankyou",
      state: {
        is_free_product: this.state.total_amount - this.state.discount == 0,
      },
    });
  };

  updateData = (data) => {
    this.setState({
      public_token: data?.Razorpay?.public_token,
      is_oAuth_done: data?.Razorpay?.is_oAuth_done,
      gatewayDta: data,
    });
  };

  changePaymentType = async (str) => {
    this.setState({ select_payment_gateway: str });
    this.setState({ show_payment_popup: false });
    // console.log("str",str)
    let amount = this.state.total_amount - this.state.discount;
    if (
      str == "razorpay" &&
      this.props.user_info.enable_razorpay_gst_fee_from_student == 1 &&
      amount > 0
    ) {
      let extra_charge =
        (Number(amount) *
          Number(this.props.user_info.razor_payment_gateway_percentage_fee)) /
        100;
      let amount_with_charge =
        Number(amount) + extra_charge + (extra_charge * 18) / 100;
      amount_with_charge = amount_with_charge.toFixed(2);
      this.setState(
        {
          apply_extra_charge: true,
          amount_with_charge,
          extra_charge: amount_with_charge - amount,
        },
        () => {
          this.createOrder();
        }
      );
    } else if (str == "atom") {
      this.setState(
        {
          apply_extra_charge: true,
          amount_with_charge: amount,
          extra_charge: 0,
        },
        () => {
          this.createOrder();
        }
      );
    } else {
      this.setState(
        {
          apply_extra_charge: false,
          amount_with_charge: amount,
          extra_charge: 0,
        },
        () => {
          this.createOrder();
        }
      );
    }
  };

  handleOk = () => {
    this.setState({ show_alert: false }); // This will hide the popup when OK is clicked
  };

  render() {
    const {
      accept_tc,
      loader,
      success,
      cart_products,
      currency_code,
      button_disabled,
      country_id,
      cart_contain_multiple_country_products,
      available_offers,
      total_amount,
      available_offers_block_open,
      discount,
      coupon_code,
      coupon_applied,
      coupon_details,
      cart_contain_purchased_products,
      loader1,
    } = this.state;
    const { cart, user_id, enable_online_payment_feature, access_token } =
      this.props;
    let allow_countries_for_payment = [
      "INR",
      "AUD",
      "BRL",
      "CAD",
      "CNY",
      "CZK",
      "DKK",
      "EUR",
      "HKD",
      "HUF",
      "ILS",
      "JPY",
      "MYR",
      "MXN",
      "TWD",
      "NZD",
      "NOK",
      "PHP",
      "PLN",
      "GBP",
      "RUB",
      "SGD",
      "SEK",
      "CHF",
      "THB",
      "USD",
    ];
    return (
      <>
        <ToastMessage />
        <Header />
        {this.state.show_payment_popup && (
          <PaymentGatewayModal
            {...this.state}
            {...this.props.user_info}
            changePaymentType={this.changePaymentType}
            handleOk={() => {
              this.createOrder();
              this.setState({ show_payment_popup: false });
            }}
            handleClose={() => {
              this.setState({ show_payment_popup: false });
              this.setState({ button_disabled: false });
            }}
          />
        )}

        {!loader && success && cart && cart.length > 0 && (
          <div className="content bgimg2" style={{ paddingTop: 100 }}>
            <div
              className="cart"
              style={{ minHeight: window.innerHeight - 200 }}
            >
              <div className="cartLeft">
                <div className="offers">
                  <div
                    onClick={() => {
                      this.setState({
                        available_offers_block_open:
                          !available_offers_block_open,
                      });
                    }}
                    className="offersTop"
                  >
                    <h5>
                      <i
                        className="fa fa-tag"
                        style={{ marginRight: 4, color: colors.JADE }}
                      ></i>{" "}
                      Available Offers
                    </h5>
                    <a
                      onClick={() => {
                        this.setState({
                          available_offers_block_open:
                            !available_offers_block_open,
                        });
                      }}
                    >
                      <i
                        className={
                          available_offers_block_open
                            ? "fa fa-chevron-up"
                            : "fa fa-chevron-down"
                        }
                      ></i>
                    </a>
                  </div>

                  {available_offers_block_open &&
                    available_offers.map((item, i) => (
                      <ul className="offerBlock">
                        {item.discount_type == "Percentage" && (
                          <li>
                            <span>{item.offer_code}</span>
                            {` up to ${item.discount_percentage}% off`}
                          </li>
                        )}
                        {item.discount_type !== "Percentage" && (
                          <li>
                            <span>{item.offer_code}</span>
                            {` Flat ${item.flat_discount_amount} ${
                              item.country_details &&
                              item.country_details.currency_code
                                ? item.country_details.currency_code
                                : ""
                            }  discount`}
                          </li>
                        )}
                      </ul>
                    ))}

                  {available_offers_block_open &&
                    available_offers.length == 0 && (
                      <h6
                        style={{
                          paddingLeft: 20,
                          paddingTop: 10,
                          opacity: 0.5,
                        }}
                      >
                        {messages.no_offers}
                      </h6>
                    )}
                </div>

                <div className="heading">
                  <h5>
                    My Cart{" "}
                    {cart && cart.length > 0
                      ? "  (" + cart.length + " item)"
                      : ""}
                  </h5>
                  {!cart_contain_multiple_country_products && (
                    <h5>Total Amount : {total_amount + " " + currency_code}</h5>
                  )}
                </div>

                {cart_products.map((item, i) => (
                  <div key={i} className="cartItem">
                    <div className="cartItemLeft">
                      <img src={item.logo_url} />
                    </div>

                    <div className="cartItemRight">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>{item.title}</h5>

                        {item.duration && item.duration != 0 ? (
                          <h6>
                            Validity : <span>{item.duration} days</span>
                          </h6>
                        ) : (
                          <h6>
                            Validity :{" "}
                            <span>
                              {new Date(
                                item.valid_from_date
                              ).toLocaleDateString() +
                                " - " +
                                new Date(
                                  item.valid_to_date
                                ).toLocaleDateString()}
                            </span>
                          </h6>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {item.is_enrolled && (
                          <h6 style={{ color: colors.JADE, fontWeight: 700 }}>
                            Purchased
                          </h6>
                        )}
                        {!item.is_enrolled && item.is_paid == "N" && (
                          <h6 style={{ color: colors.JADE, fontWeight: 700 }}>
                            Free
                          </h6>
                        )}
                        {!item.is_enrolled && item.is_paid == "Y" && (
                          <h6 style={{ color: colors.RED, fontWeight: 700 }}>
                            {item.price}{" "}
                            {item.country_details &&
                            item.country_details.currency_code
                              ? item.country_details.currency_code
                              : ""}
                          </h6>
                        )}
                        <a
                          onClick={() => {
                            this.remove(item);
                          }}
                        >
                          <i className="fa fa-trash-o"></i> Remove
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="cartRight">
                {cart_contain_multiple_country_products && (
                  <div className="priceBlock">
                    <h5>
                      <span>Note - </span>
                      {messages.cart_contain_multiple_country_products}
                    </h5>
                  </div>
                )}
                {(!allow_countries_for_payment.includes(currency_code) ||
                  enable_online_payment_feature == false) &&
                  !cart_contain_multiple_country_products && (
                    <div className="priceBlock">
                      <h5>
                        <span>Note - </span>
                        {messages.payment_not_allowed}
                      </h5>
                    </div>
                  )}

                {!cart_contain_multiple_country_products &&
                  allow_countries_for_payment.includes(currency_code) &&
                  enable_online_payment_feature == true && (
                    <div className="priceBlock">
                      {total_amount > 0 && <h6>Apply Coupons </h6>}

                      {!coupon_applied && total_amount > 0 && (
                        <div className="coupon">
                          <input
                            placeholder="Enter coupon code"
                            value={coupon_code}
                            onChange={(event) => {
                              this.setState({
                                coupon_code: event.target.value,
                              });
                            }}
                          />
                          <button
                            onClick={this.applyCoupon}
                            disabled={
                              coupon_code.trim() == "" || button_disabled
                            }
                            style={{
                              opacity:
                                coupon_code.trim() == "" || button_disabled
                                  ? 0.5
                                  : 1,
                            }}
                            className="apply"
                          >
                            Apply
                          </button>
                        </div>
                      )}

                      {coupon_applied && total_amount > 0 && (
                        <div className="coupon">
                          <h4 style={{ color: colors.JADE, fontSize: 12 }}>
                            {" "}
                            <i className="fa fa-check-circle"></i> Coupon
                            Applied Successfully!
                          </h4>
                          <i
                            onClick={() => {
                              this.setState({
                                discount: 0,
                                coupon_code: "",
                                coupon_applied: false,
                                coupon_details: {},
                              });
                            }}
                            className="fa fa-remove"
                            style={{ color: "red" }}
                          ></i>
                        </div>
                      )}

                      <div className="priceDetails">
                        <h6>Price Details {` ( ${cart.length} Items)`}</h6>

                        <div className="price">
                          <h4>Amount </h4>
                          <h4>
                            {total_amount} {currency_code}
                          </h4>
                        </div>

                        <div className="price">
                          <h4>Discount </h4>
                          <h4>
                            - {discount} {currency_code}
                          </h4>
                        </div>

                        <div className="price">
                          <h4>Amount Payable </h4>
                          <h4 style={{ fontWeight: "800", fontSize: 14 }}>
                            {total_amount - discount} {currency_code}
                          </h4>
                        </div>
                      </div>

                      <div className="check">
                        <input
                          type="checkbox"
                          id="tc"
                          name="tc"
                          value={accept_tc}
                          checked={accept_tc}
                          onChange={() => {
                            this.setState({ accept_tc: !accept_tc });
                          }}
                        />
                        <label for="tc">
                          <span style={{ fontSize: 12 }}>
                            I agree to the
                            <Link
                              to={{
                                pathname: `/more/terms-and-condition`,
                                state: {
                                  label: "TERMS & CONDITION",
                                  slug: "/terms-and-condition",
                                  hide_image: true,
                                },
                              }}
                            >
                              {" Terms & Conditions."}
                            </Link>
                          </span>
                        </label>
                      </div>
                      <Alert
  show={this.state.show_alert}
  heading={this.state.heading}
  content={this.state.content}
  handleClose={this.handleClose}
  handleOk={this.handleOk} 
  hideClose={true}
/>
                      
                        {console.log(this.state)}
                        {console.log(this.state.gatewayDta?.Razorpay?.is_active)}
{console.log("n",total_amount - discount,user_id)}

<button
  className="payButton"
  onClick={async () => {
    // Redirect to login if user_id === 0
    if (user_id === 0) {
      this.props.history.replace({
        pathname: "/login",
        state: { from_cart: true },
      });
      return;
    }

    this.setState({ button_disabled: true });

    if (!accept_tc) {
      this.setState({
        show_alert: true,
        content: "Please accept the terms and conditions.",
        button_disabled: false,
      });
      return;
    }

    let amount = total_amount - discount;

    if (amount === 0) {
      this.createOrder();
      return;
    }

    if (cart_contain_purchased_products) {
      this.setState({
        show_alert: true,
        content: "Please remove already purchased products from cart!",
        button_disabled: false,
      });
      return;
    }

    // Check if payment gateway is active
    if (
      this.state.gatewayDta?.Razorpay?.is_active === "Y" ||
      this.state.gatewayDta?.Atom?.is_active === "Y" ||
      amount === 0
    ) {
      getPaymentType(
        {
          ...this.props,
          ...this.state,
          amount,
          setLoader: (val) => this.setState({ loader1: val }),
          message: this.message,
          updateData: this.updateData,
          setType: (paymentType) => {
            if (paymentType === "both") {
              this.setState({ show_payment_popup: true });
            } else if (
              paymentType === "razorpay" &&
              this.props.user_info.enable_razorpay_gst_fee_from_student === 1 &&
              amount > 0
            ) {
              let extra_charge =
                (Number(amount) *
                  Number(this.props.user_info.razor_payment_gateway_percentage_fee)) /
                100;
              let amount_with_charge =
                Number(amount) + extra_charge + (extra_charge * 18) / 100;
              amount_with_charge = amount_with_charge.toFixed(2);
              this.setState({
                apply_extra_charge: true,
                show_extra_charge_pop_up: true,
                amount_with_charge,
                extra_charge: amount_with_charge - amount,
                button_disabled: false,
              });
            } else {
              this.setState(
                {
                  select_payment_gateway: paymentType,
                  button_disabled: false,
                  apply_extra_charge: true,
                  amount_with_charge: amount,
                  extra_charge: 0,
                },
                () => this.createOrder()
              );
            }
          },
        },
        this.opnAlrtPopForNotIntegratePayGat
      );
    } else {
      this.setState({
        show_alert: true,
        content: "You are not allowed to do payment from web.",
        button_disabled: false,
      });
    }
  }}
>
  Proceed to pay
</button>




                      
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}

        {(loader || loader1) && (
          <div className="content">
            <div className="loading"></div>
          </div>
        )}

        {!loader && cart && cart.length == 0 && success && (
          <div className="content bgimg2" style={{ paddingTop: 70 }}>
            <div
              className="emptyCart"
              style={{ height: window.innerHeight - 200 }}
            >
              <i
                className="fa fa-shopping-cart"
                style={{ marginBottom: 30, fontSize: 100 }}
              ></i>
              <h4>There are no items in your Cart!</h4>
              <button
                onClick={() => {
                  this.props.history.push("/product");
                }}
              >
                Add Products to Cart
              </button>
            </div>
          </div>
        )}

        {!loader && !success && (
          <ErrorPage
            onReload={() => {
              this.setState({ loader: true });
              this.getCartProducts();
            }}
            message={""}
          />
        )}

        <Alert
          show={this.state.show_alert}
          heading={this.state.heading}
          content={this.state.content}
          handleClose={this.handleClose}
          handleOk={this.handleOk}
          hideClose={true}
          link={
            this.state.add_link
              ? this.props.data.social_details.android_app_url
                ? this.props.data.social_details.android_app_url
                : "https://play.google.com/store/apps/details?id=com.proctur.procturlearnstudent"
              : undefined
          }
        />

        <ExtraCharge
          {...this.state}
          handleOk={() => {
            this.createOrder();
            this.setState({ show_extra_charge_pop_up: false });
          }}
          handleClose={() => {
            this.setState({ show_extra_charge_pop_up: false });
          }}
        />

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.cart, ...state.auth, ...state.home };
};

export default connect(mapStateToProps)(withRouter(MyCart));
