import React, { Component } from 'react'
import InformationLogo from '../../assets/information.png';
import MailLogo from '../../assets/mail.png';
import Avatar from '../../assets/profile.png';
import { dashboard } from '../../assets';


export const HeaderStrip = ({ institute_name, navigate, onLogout, user_info }) => {
    return (
        <header>
            <div className="dashboard-header">
                <div className="header-left">
                    <div id="show-sidebar" className="btn btn-sm btn-dark" onClick={() => { document.body.classList.toggle('toggled') }} >
                        <i className="fa fa-bars"></i>
                    </div>
                    <p>{institute_name}</p>
                </div>
                <div className="header-right" >
                    {console.log("logout")}
                    <button onClick={() => { navigate.push('/') }} >Go to website</button>
                    {/* <span><img src={MailLogo} className="m20 head_img" /></span> */}
                    {/* <span><img onClick={() => { navigate.push('/dashboard/my-orders') }} src={dashboard.order} className="m20 head_img" /></span> */}
                    {/* <span><img src={InformationLogo} className="m20 head_img" /></span> */}
                    {/* <span><img
                        // onClick={() => { navigate.push('/dashboard/profile') }} 
                        src={Avatar} className="m20 head_img" />
                    </span> */}
                    <span className="logout" onClick={onLogout}><i className="fa fa-sign-out" ></i></span>
                </div>
            </div>
        </header>
    )
}